<template>
  <div class="social-block" v-if="getSocialMedia">
    <button type="button" @click="gotToSocialNetwork" :class="`social-btn ${socialNetwork}`" target="_blank">
      <iconic :name="socialNetwork" />
    </button>
  </div>
</template>

<script>
  import { dictionary } from "@/assets/js/dictionary";
  import extractUserName from "@/utils/extractUsername/extractUsername";

  export default {
    props: {
      socialNetwork: String,
      username: String,
    },
    computed: {
      getSocialMedia() {
        return dictionary?.networksInfo[this.socialNetwork];
      },
    },
    methods: {
      gotToSocialNetwork() {
        const userName = extractUserName(this.username, this.socialNetwork);
        const baseUrl = this.getSocialMedia.baseUrl;

        if (!userName) {
          this.$emit("notify", `Error: Enlace de red social inválido. Verifica posible duplicación en la URL.`);
          return;
        }
        this.$emit("notify", "");
        const url = `${baseUrl}${userName}`;
        window.open(url, "_blank", "noopener");
      },
    },
  };
</script>

<style lang="scss">
  @import "@/assets/styles/vars.scss";
  $iconSize: 36px;
  .social-block {
    .social-btn {
      border: none;
      outline: none;
      cursor: pointer;
      @include Flex();
      font-size: 100%;
      width: 25px;
      height: 25px;
      line-height: $iconSize;
      text-align: center;
      color: #fff;
      border-radius: 20px;
      &:not(:last-child) {
        margin-right: 10px;
      }
      &.facebook {
        background: #1876f2;
      }
      &.instagram {
        background: #f09433;
        background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
      }
      &.onlyfans {
        font-size: 25px;
      }
      &.tiktok {
        background: #000;
      }
    }
  }
</style>
