<template>
  <div class="modelRecordEdit" v-if="post">
    <div class="modelRecordEdit-form form-data">
      <div class="form-data-input">
        <div role="label" required>Nombres</div>
        <input
          type="text"
          name="name"
          placeholder="Ingrese nombres"
          required
          @blur="$validate.validateInput($event)"
          :value="post.name"
          autocomplete="off"
        />
      </div>
      <div class="form-data-input">
        <div role="label" required>Teléfono</div>
        <div class="container-telInfo">
          <InputPostalCode :currentPostalCode="post.inputPostalCode || ''" @postalCode="updatePostalCode" />
          <input type="tel" name="phone" placeholder="Ingrese teléfono" :value="post.phone" required @blur="$validate.validateInput($event)" />
        </div>
      </div>
      <div class="form-data-input">
        <input type="hidden" name="inputPostalCode" :value="currentPostalCode || post.inputPostalCode" />
      </div>
      <div class="form-data-input">
        <div role="label" required>Correo electrónico</div>
        <input type="email" name="email" placeholder="Ingrese email" :value="post.email" required @blur="$validate.validateInput($event)" />
      </div>
      <div class="form-data-input">
        <div role="label" required>Fecha de nacimiento</div>
        <DMYDate name="birthDayDate" placeholder="Fecha de nacimiento" required :value="post.birthDayDate" />
      </div>
      <div :class="['form-data-input', post.idUserCollection && 'disabled']">
        <CitiesSelect open="true" name="city" :value="post.city" attr="w100" :current="post.city" readonly />
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import DMYDate from "../inputs/DMYDate.vue";
import CitiesSelect from "../inputs/CitiesSelect";
import InputPostalCode from "../web/InputPostalCode.vue";
export default {
  components: { DMYDate, CitiesSelect, InputPostalCode },
  props: {
    post: Object,
  },
  data: function () {
    return {
      currentPostalCode: "",
    };
  },
  methods: {
    updatePostalCode: function (value, manuallyChanged) {
      if (manuallyChanged) this.currentPostalCode = value;
    },
  },
};
</script>

<style lang="scss">
.container-telInfo {
  display: flex;
  .postal-code {
    width: 20%;
    margin-right: 5px;
  }
}
.form-data-input {
  position: relative;
}
.modelRecordEdit {
  .disabled {
    pointer-events: none;
    opacity: 0.8;
    color: gray;
  }
}
</style>
