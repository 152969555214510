<template>
  <div class="city-radio" role="select-radio" v-bind="initCities" v-if="open || (cities && visible)" w100>
    <div role="option" v-for="(city, ctkey) in cities" :key="ctkey" w100>
      <input class="mycheck circle" type="radio" :id="`city-${ctkey}-${elementID}`" :name="name" :value="ctkey" :checked="ctkey === value" />
      <label block w100 :for="`city-${ctkey}-${elementID}`">{{ city }}</label>
    </div>
    <div role="option" v-if="!cities[value]" w100>
      <input class="mycheck circle" type="radio" :id="`city-other-${elementID}`" :name="name" :value="value" :checked="true" />
      <label block w100 :for="`city-other-${elementID}`">{{ value }}</label>
    </div>
  </div>
</template>

<script>
import store from "../../store/index.js";
import global from "../../assets/js/global";
export default {
  props: {
    value: String,
    name: String,
    open: null,
    attr: null,
  },
  data: () => {
    return {
      elementID: global.fastID(),
      locale: null,
      cities: {},
      visible: false,
    };
  },
  methods: {
    toggle: function () {
      this.visible ? (this.visible = false) : (this.visible = true);
    },
  },
  computed: {
    initCities: async function () {
      this.locale = await store.getters["localeLang"];
      this.cities = this.locale.words.cities;
      delete this.cities.others;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/vars.scss";
.city-radio {
  display: inline-block;
  margin-bottom: 5px;
  border: solid 1px $dusty_gray;
  padding: $mpadding/2;
  border-radius: $mpadding/2;
  background: #fff;
  [role="option"] {
    margin: 5px 0;
    input {
      margin-right: 5px;
      &:checked + * {
        font-weight: bold;
      }
    }
    &:hover {
      color: $primary_color;
    }
  }
}
</style>
