<template>
  <div class="photo-gallery-visor" v-if="visor">
    <VueSlickCarousel v-bind="settings" class="carrousel centered">
      <div class="photo-wrap" v-for="(photo, pidx) in photos" :key="pidx" prevent>
        <img class="animated-fast fadeIn" :src="photo.link" :alt="photo.alt || 'images'" prevent />
      </div>
    </VueSlickCarousel>
    <a class="photo-gallery-visor-close-btn pointer" @click="photoView({ action: 'close' })">
      <i class="fa fa-times"></i>
    </a>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
export default {
  components: { VueSlickCarousel },
  data: () => {
    return {
      visor: false,
      photos: null,
      settings: {
        arrows: true,
        dots: false,
        slidesToShow: 1,
        mobileFirst: true,
        infinite: false,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              arrows: false,
            },
          },
        ],
      },
    };
  },
  methods: {
    photoClean: function (photos) {
      const nPhotos = [];
      Object.keys(photos).map((i) => {
        photos[i].link ? nPhotos.push(photos[i]) : "";
      });
      return nPhotos;
    },
    photoView: function (params) {
      const mbody = document.querySelector("body");
      if (params.action == "open") {
        document.addEventListener(
          "keydown",
          (event) => {
            if (event.key === "Escape") {
              this.visor = null;
              mbody.classList.remove("onphoto");
            }
          },
          false
        );
        this.photos = this.photoClean(params.photos);
        mbody.classList.add("onphoto");
        this.settings.initialSlide = params.idx;
        this.visor = true;
        setTimeout(function () {
          const elmn = document.querySelector(".photo-gallery-visor .slick-slide");
          elmn.focus();
        }, 100);
      } else if (params.action == "close") {
        this.visor = null;
        mbody.classList.remove("onphoto");
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/vars.scss";
.photo-gallery-visor {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.9);
  backdrop-filter: blur(10px);
  z-index: 999;
  img {
    max-width: 100%;
    width: auto;
    max-height: 100vh;
    margin: 0 auto;
  }
  &-close-btn {
    position: fixed;
    top: $mpadding * 2;
    right: $mpadding * 2;
    width: 44px;
    height: 44px;
    line-height: 44px;
    z-index: 2;
    color: $white;
    background-color: $primary_color_50;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
    text-align: center;
    &:hover {
      background-color: $primary_color;
    }
  }
  .slick-track {
    display: flex;
    align-items: center;
    height: 100%;
    overflow-y: auto;
  }
  .slick-track:active {
    cursor: move;
  }
  .slick-prev,
  .slick-next {
    z-index: 1;
    height: 100%;
    width: $mpadding * 4;
    position: fixed;
    top: 50%;
  }
  .slick-prev::before,
  .slick-next::before {
    content: "";
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    top: calc(50% - 22px);
    left: 0;
    border-radius: 50%;
    font-family: fontawesome;
    background-color: $primary_color_opacity;
    text-align: center;
  }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0;
  }
  .slick-prev {
    left: 0;
    padding-left: $mpadding * 2;
    margin-left: $mpadding;
  }
  .slick-next {
    right: 0;
    padding-right: $mpadding * 2;
    margin-right: $mpadding;
  }
  .slick-prev::before {
    content: "\f053";
  }
  .slick-next::before {
    content: "\f054";
  }
}
</style>
