<template>
  <div class="dmy-date" :id="`dmy-date-${elementID}`" v-bind="startDate">
    <div class="dmy-date-inputs" @change="valimydate" v-if="locale">
      <div class="wrp-input">
        <label>{{ locale.words.day }}</label>
        <select id="days" v-if="days" v-model="day">
          <option value="0" disabled selected>{{ locale.words.day }}</option>
          <option v-for="(day, didx) in days" :value="didx + 1" :key="`day-${didx}`">{{ day }}</option>
        </select>
      </div>
      <div class="wrp-input">
        <label>{{ locale.words.month }}</label>
        <select id="months" v-if="months" v-model="month">
          <option value="0" disabled selected>{{ locale.words.month }}</option>
          <option v-for="(month, midx) in months" :value="midx + 1" :key="`month-${midx}`">{{ month.name }}</option>
        </select>
      </div>
      <div class="wrp-input">
        <label>{{ locale.words.year }}</label>
        <select id="years" v-if="years" v-model="year">
          <option value="0" disabled selected>{{ locale.words.year }}</option>
          <option v-for="(year, yidx) in years" :value="year" :key="`year-${yidx}`">{{ year }}</option>
        </select>
      </div>
    </div>
    <div>
      <input type="text" hidden class="result" :required="this.required" :name="name" :placeholder="this.placeholder" :value="localValue" />
    </div>
  </div>
</template>

<script>
  import global from "../../assets/js/global";
  import store from "../../store/index.js";
  export default {
    data: () => {
      return {
        day: 0,
        month: 0,
        year: 0,
        days: [],
        inputValue: "",
        months: [],
        years: [],
        cyear: new Date().getFullYear() - 14,
        ylimit: 0,
        locale: null,
        elementID: global.fastID(),
        lang: "",
        localValue: "",
      };
    },
    props: {
      name: String,
      placeholder: String,
      required: Boolean,
      value: String,
    },
    methods: {
      isDay: function (day) {
        return day > 0 && day <= 31;
      },
      isMonth: function (month) {
        return month > 0 && month <= 12;
      },

      isYear: function (year) {
        return year > 0 && year <= this.cyear - this.ylimit;
      },
      valimydate: function () {
        let isComplete = false;
        const myInput = document.querySelector(`#dmy-date-${this.elementID} .result`);
        if (this.isDay(this.day) && this.isMonth(this.month) && this.isYear(this.year)) {
          this.localValue = `${this.year}-${this.month < 10 ? "0" + this.month : this.month}-${this.day < 10 ? "0" + this.day : this.day}`;
          isComplete = true;
        }
        const daysMonth = this.month > 0 ? this.months[this.month - 1]["days"] : 31;

        if (this.days.length != daysMonth || (this.days.length != daysMonth && this.days.length > 0)) {
          this.days = [];

          for (let d = 1; d <= daysMonth; d++) {
            this.days.push(d);
          }

          if (this.day > daysMonth) {
            this.day = daysMonth;
          }
        }

        if (this.required) {
          if (isComplete) {
            myInput.nextElementSibling ? myInput.nextElementSibling.remove() : "";
            this.$emit("date", this.localValue);
            this.$emit("isSuccess", myInput);
          } else {
            myInput.nextElementSibling ? myInput.nextElementSibling.remove() : "";
            myInput.insertAdjacentHTML("afterend", '<div class="tooltip data-error">' + myInput.getAttribute("placeholder") + "</div>");
            this.inputValue = "";
          }
        }
      },
      monthName: function (date) {
        return global.monthName(date);
      },
      getDataLang: async function () {
        this.locale = await store.getters["locale"];
      },
      setValue: function () {
        let cValue = this.value;
        if (cValue) {
          cValue = new Date(cValue);
          const getTime = cValue.getTime() + cValue.getTimezoneOffset() * 60 * 1000;
          cValue = new Date(cValue.setTime(getTime));

          this.year = cValue.getFullYear();
          this.month = cValue.getMonth() + 1;
          this.day = cValue.getDate();
          this.localValue = cValue;
          this.$emit("date", this.localValue.toString());
        }
      },
    },
    computed: {
      startDate: async function () {
        this.lang = localStorage.getItem("user-language") || "es";
        this.months = this.$global.dictionary.months.map((month) => ({ name: month[this.lang], days: month.days }));
        await this.getDataLang();
        this.days = [];
        this.years = [];
        for (let d = 1; d <= 31; d++) {
          this.days.push(d);
        }
        for (let y = this.cyear - this.ylimit; y >= this.cyear - this.ylimit - 100; y--) {
          this.years.push(y);
        }
      },
    },
    mounted: async function () {
      this.localValue = this.value;
      this.setValue();
    },
  };
</script>

<style lang="scss" scoped>
  @import "@/assets/styles/vars.scss";
  .dmy-date {
    &-inputs {
      display: flex;
      flex: 1;
      label {
        font-size: 90%;
      }
      > * {
        width: 33%;
        &:first-child {
          margin-right: $mpadding/2;
        }
        &:nth-child(2) {
          margin: 0 $mpadding/2;
        }
        &:nth-child(3) {
          margin-left: $mpadding/2;
        }
      }
      .wrp-input {
        position: relative;
        select {
          max-width: 100%;
          padding: 7.5px;
          border: solid 1px #bfbfbf;
          border-radius: 6px;
          width: 100%;
          height: 37px;
          box-shadow: 0 0 0;
          font: 400 100% Montserrat, sans-serif;
        }
      }
    }
  }
</style>
