import { dictionary } from "@/assets/js/dictionary";

const getUsername = (urlOrUsername = "", network = null) => {
  urlOrUsername = urlOrUsername.replace(/\s+/g, "");
  const networkInfo = dictionary.networksInfo[network];
  const regex = networkInfo?.regex;

  if (!isURL(urlOrUsername)) {
    return getUsernameBySocialNetworkPattern(urlOrUsername);
  }

  if (regex) {
    return getUsernameByRegex(urlOrUsername, regex);
  }
  return getUsernameByURL(urlOrUsername);
};

const isURL = (urlOrUsername) => {
  return urlOrUsername.includes(".com");
};

const getUsernameByRegex = (url, regex) => {
  const usernameMatch = url.match(regex);
  if (usernameMatch && usernameMatch[1]) {
    return usernameMatch[1];
  }
  return null;
};

const getUsernameByURL = (url) => {
  const parts = url.split(".com/");
  if (parts.length > 1) {
    return parts[1];
  }
  return null;
};

const getUsernameBySocialNetworkPattern = (username) => {
  if (username.includes("@")) {
    return username.replace("@", "");
  }
  return username;
};

export default getUsername;
